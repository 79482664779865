@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Thin version */
    @font-face {
        font-family: 'MoreSugar';
        src: url('./assets/more-sugar.thin.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    /* Regular version */
    @font-face {
        font-family: 'MoreSugar';
        src: url('./assets/more-sugar.regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    /* Card Game - Regular */
    @font-face {
        font-family: 'Minecraft';
        src: url('./assets/Minecraft.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
}
